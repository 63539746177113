import '@/js/dist/www.iichi.com/common';

import Vue from 'vue';
import { pinia } from '@/js/lib/store';
import { useItemStore } from '@/js/lib/store/item';
import { useEventStore } from '@/js/lib/store/event';
import { uniq } from '@/js/lib/helper/array';
import { scrollToElement } from '@/js/lib/helper/smooth-scroll';
import { addFollowButton, addFollowRecommend } from '@/js/lib/ui/follow-button';
import Slider from '@/js/components/common/ui/slider.vue';
import LikeButtonSmall from '@/js/components/www.iichi.com/organisms/likeButtonSmall.vue';
import EventLikeButtonSmall from '@/js/components/www.iichi.com/organisms/eventLikeButtonSmall.vue';
import MessageButton from '@/js/components/listing/MessageButton.vue';
import ListingSort from '@/js/components/www.iichi.com/pages/shop/ListingSort.vue';
import ShopInfoButton from '@/js/components/www.iichi.com/pages/shop/ShopInfoButton.vue';

const eventStore = useEventStore(pinia);

document.addEventListener('DOMContentLoaded', async () => {
  await addFollowButton();
  addFollowRecommend();

  if (document.querySelector('message-button')) {
    new Vue({
      el: 'message-button',
      components: {
        MessageButton,
      },
    });
  }

  const tabEl: HTMLElement = document.querySelector('.ui-tab');
  if (location.hash === '#tab') {
    scrollToElement({ el: tabEl });
  }
  document.querySelectorAll('a[href*="#tab"]').forEach((a: HTMLAnchorElement) => {
    const { pathname, search } = new URL(a.href);
    if (pathname === window.location.pathname) {
      a.addEventListener('click', (event) => {
        const mySort = new URLSearchParams(window.location.search).get('sort');
        if (mySort === new URLSearchParams(search).get('sort')) {
          event.preventDefault();
          scrollToElement({ el: tabEl });
        } else {
          window.location.href = a.href;
        }
      });
    }
  });

  const tabUlEl = document.querySelector('.ui-tab ul');
  const tabActiveEl = tabUlEl.querySelector('.active');
  if (tabActiveEl) {
    const { x } = tabActiveEl.getBoundingClientRect();
    tabUlEl.scrollTo({
      left: x - window.innerWidth / 2 + tabActiveEl.clientWidth / 2,
      behavior: 'smooth',
    });
  }

  const sliderEl = document.querySelector('.about-me-slide');
  if (sliderEl) {
    new Vue({
      el: sliderEl,
      components: {
        Slider,
      },
    });
  }

  const listingSortEl = document.querySelector('listing-sort');
  if (listingSortEl) {
    new Vue({
      el: listingSortEl,
      components: {
        ListingSort,
      },
    });
  }

  if (document.querySelector('shop-info-button')) {
    new Vue({
      el: 'shop-info-button',
      components: {
        ShopInfoButton,
      },
    });
  }

  const listEl = document.querySelector('#main .listing');
  if (listEl) {
    const itemStore = useItemStore(pinia);
    const itemIds = Array.from(document.querySelectorAll('like-button-small'), (el) => Number(el.getAttribute(':item-id')));
    itemStore.initLikedData(uniq(itemIds));

    new Vue({
      el: listEl,
      components: {
        LikeButtonSmall,
      },
    });
  }

  const eventEl = document.querySelector('#events');
  if (eventEl) {
    const eventIds = Array.from(document.querySelectorAll('event-like-button-small'), (el) => Number(el.getAttribute(':event-id')));
    eventStore.initLikedData(uniq(eventIds));

    new Vue({
      el: eventEl,
      components: {
        EventLikeButtonSmall,
      },
    });
  }
});
